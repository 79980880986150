export const TRANSACTION_TYPE = [
  {
    id: 0,
    label: 'bo_admin',
    value: 'admin',
    background: 'inherit',
    bootstrapClassName: 'circle-red text-red',
  },
  {
    id: 1,
    label: 'bo_offer_type_sell',
    value: 'sell_emoney',
    background: '#b3e5fc',
    bootstrapClassName: 'circle-orange text-orange',
  },
  {
    id: 2,
    label: 'bo_offer_transaction_type_buy',
    value: 'buy_emoney',
    background: '#0408491A',
    bootstrapClassName: 'circle-blue text-blue',
  },
  {
    id: 3,
    label: 'bo_offer_type_match',
    value: 'match_emoney',
    background: '#ffd8b2',
    bootstrapClassName: 'circle-green text-green',
  },
  {
    id: 4,
    label: 'bo_balance_correction',
    value: 'addition_ewallet',
    background: '#c8e6c9',
    bootstrapClassName: 'circle-yellow text-yellow',
  },
  {
    id: 5,
    label: 'bo_modify_balance',
    value: 'deduction_ewallet',
    background: '#ffcdd2',
    bootstrapClassName: 'circle-purple text-purple',
  },
  {
    id: 6,
    label: 'bo_direct_transfer_sent',
    value: 'immidiate.to',
    background: '#805b36',
    bootstrapClassName: 'circle-indigo text-indigo',
  },
  {
    id: 7,
    label: 'bo_direct_transfer_received',
    value: 'immidiate.from',
    background: '#bcc951bd',
    bootstrapClassName: 'circle-pink text-pink',
  },
  {
    id: 8,
    label: 'bo_invoice_received_hola_money_label',
    value: 'invoice_money_received',
    background: '#bcc951bd',
    bootstrapClassName: 'text-cadet-blue circle-cadet-blue',
  },
  {
    id: 9,
    label: 'bo_invoice_sent_hola_money_label',
    value: 'invoice_money_sent',
    background: '#bcc951bd',
    bootstrapClassName: 'text-chocolate circle-chocolate',
  },
  {
    id: 10,
    label: 'bo_invoice_received_crypto_label',
    value: 'invoice_crypto_received',
    background: '#bcc951bd',
    bootstrapClassName: 'text-tomato circle-tomato',
  },
  {
    id: 11,
    label: 'bo_invoice_sent_crypto_label',
    value: 'invoice_crypto_sent',
    background: '#bcc951bd',
    bootstrapClassName: 'text-dark-khaki circle-dark-khaki',
  },
];

export const SEND_TRANSACTION_STATUS = [
  {
    id: 1,
    label: 'bo_status_declined',
    value: 'declined',
    background: '#ffd8b2',
    color: '#805b36',
  },
  {
    id: 2,
    label: 'bo_status_done',
    value: 'done',
    background: '#ffcdd2',
    color: '#f44336',
  },
  {
    id: 3,
    label: 'bo_error_label',
    value: 'error',
    background: '#0408491A',
    color: '#040849',
  },
];

export const TRANSACTION_STATUS_HISTORY = [
  {
    id: 0,
    label: 'bo_waiting_label',
    value: 'tran_waiting_bankdetails',
    background: '#0408491A',
    color: '#040849',
  },
  {
    id: 1,
    label: 'bo_waiting_label',
    value: 'tran_waiting_transfer',
    background: '#0408491A',
    color: '#040849',
  },
  {
    id: 2,
    label: 'bo_status_pending',
    value: 'pending_transaction',
    background: '#ffd8b2',
    color: '#805b36',
  },
  {
    id: 3,
    label: 'bo_transaction_status_completed',
    value: 'tran_completed',
    background: '#c8e6c9',
    color: '#4caf50',
  },
  {
    id: 4,
    label: 'bo_status_canceled',
    value: 'tran_canceled',
    background: '#ffcdd2',
    color: '#f44336',
  },
  {
    id: 5,
    label: 'bo_transaction_status_timeout',
    value: 'tran_expired',
    background: '#0408491A',
    color: '#040849',
  },
  {
    id: 6,
    label: 'bo_transaction_status_transferred',
    value: 'tran_transferred',
    background: '#b3e5fc',
    color: '#23547b',
  },
  {
    id: 7,
    label: 'bo_status_pending',
    value: 'tran_pending',
    background: '#ffd8b2',
    color: '#805b36',
  },
];

export const TRANSACTION_STATUS = [
  {
    id: 0,
    label: 'bo_waiting_label',
    value: 'tran_waiting_bankdetails',
    background: '#0408491A',
    color: '#040849',
  },
  {
    id: 1,
    label: 'bo_waiting_label',
    value: 'tran_waiting_transfer',
    background: '#0408491A',
    color: '#040849',
  },
  {
    id: 2,
    label: 'bo_status_pending',
    value: 'pending_transaction',
    background: '#ffd8b2',
    color: '#805b36',
  },
  {
    id: 3,
    label: 'bo_transaction_status_completed',
    value: 'tran_completed',
    background: '#c8e6c9',
    color: '#4caf50',
  },
  {
    id: 4,
    label: 'bo_status_canceled',
    value: 'tran_canceled',
    background: '#ffcdd2',
    color: '#f44336',
  },
  {
    id: 5,
    label: 'bo_transaction_status_timeout',
    value: 'tran_expired',
    background: '#0408491A',
    color: '#040849',
  },
  {
    id: 6,
    label: 'bo_transaction_status_transferred',
    value: 'tran_transferred',
    background: '#b3e5fc',
    color: '#23547b',
  },
];

export const OFFER_STATUS = [
  {
    id: 1,
    label: 'bo_offer_status_placed',
    value: 'Placed',
    sqlValue: 1,
    background: '#0408491A',
    color: '#040849',
  },
  {
    id: 2,
    sqlValue: 2,
    label: 'bo_exchange_offer_status_on_going',
    value: 'On Going',
    background: '#a8a8ad',
    color: '#313133',
  },
  {
    id: 3,
    sqlValue: 3,
    label: 'bo_status_declined',
    value: 'Declined',
    background: '#ffd8b2',
    color: '#805b36',
  },
  {
    id: 4,
    sqlValue: 4,
    label: 'bo_status_canceled',
    value: 'Cancelled',
    background: '#ffcdd2',
    color: '#f44336',
  },
  {
    id: 5,
    sqlValue: 5,
    label: 'bo_status_done',
    value: 'Done',
    background: '#c8e6c9',
    color: '#4caf50',
  },
  {
    id: 6,
    sqlValue: 6,
    label: 'bo_status_blocked',
    value: 'Blocked',
    background: '#ffd8b2',
    color: '#040849',
  },
  {
    id: 7,
    sqlValue: 7,
    label: 'bo_transaction_status_transferred',
    value: 'tran_transferred',
    background: '#b3e5fc',
    color: '#805b36',
  },
];

export const P2P_TRANSACTION_TYPE = [
  {
    id: '1',
    name: 'bo_buy',
    code: 'BUY',
    value: ['BUY'],
    label: 'bo_offer_transaction_type_buy',
    background: '#ffd8b2',
    color: '#805b36',
    sqlValue: 1,
  },
  {
    id: '2',
    name: 'bo_offer_type_sell',
    code: 'SELL',
    value: ['SELL'],
    label: 'bo_offer_type_sell',
    background: '#b3e5fc',
    color: '#23547b',
    sqlValue: 2,
  },
];

export const P2P_TRANSACTION_STATUS = [
  {
    id: 1,
    label: 'bo_status_declined',
    value: 'declined',
    background: '#ffd8b2',
    color: '#805b36',
  },
  {
    id: 2,
    label: 'bo_status_done',
    value: 'done',
    background: '#ffcdd2',
    color: '#f44336',
  },
  {
    id: 3,
    label: 'bo_error_label',
    value: 'error',
    background: '#0408491A',
    color: '#040849',
  },
];

export const AGENT_TRANSACTIONS_STATUS = [
  {
    id: 0,
    label: 'bo_transaction_status_waiting_for_bank_details',
    value: 'tran_waiting_bankdetails',
    background: '#ffd8b2',
    color: '#805b36',
  },
  {
    id: 1,
    label: 'bo_waiting_label',
    value: 'tran_waiting_transfer',
    background: '#0408491A',
    color: '#040849',
  },
  {
    id: 3,
    label: 'bo_transaction_status_completed',
    value: 'tran_completed',
    background: '#c8e6c9',
    color: '#4caf50',
  },
  {
    id: 4,
    label: 'bo_status_canceled',
    value: 'tran_canceled',
    background: '#ffcdd2',
    color: '#f44336',
  },
  {
    id: 5,
    label: 'bo_transaction_status_timeout',
    value: 'tran_expired',
    background: '#0408491A',
    color: '#040849',
  },
  {
    id: 6,
    label: 'bo_transaction_status_waiting_for_confirmation',
    value: 'tran_transferred',
    background: '#b3e5fc',
    color: '#23547b',
  },
  {
    id: 7,
    label: 'bo_status_pending',
    value: 'tran_pending',
    background: '#ffd8b2',
    color: '#805b36',
  },
];

export enum cryptoWalletTransactionTypes {
  deposit,
  withdraw,
  exchange,
  transfer,
}

export enum cryptoWalletTransactionStatusTypes {
  WAITING_FOR_APPROVAL = 0,
  PROCESSING = 1,
  COMPLETED = 2,
  FAILED = 3,
  PENDING = 4,
  WAITING_FOR_WITHDRAW = 5,
  WAITING_FOR_GAS = 6,
}

export const cryptoWalletTransactionsStatusObj = {
  [cryptoWalletTransactionStatusTypes.WAITING_FOR_APPROVAL]: {
    badgeClassName: 'badge-warning',
    text: 'Pending',
  },

  [cryptoWalletTransactionStatusTypes.PENDING]: {
    badgeClassName: 'badge-warning',
    text: 'Waiting for approval',
  },

  [cryptoWalletTransactionStatusTypes.COMPLETED]: {
    badgeClassName: 'badge-success',
    text: 'Completed',
  },

  [cryptoWalletTransactionStatusTypes.FAILED]: {
    badgeClassName: 'badge-light',
    text: 'Failed',
  },

  [cryptoWalletTransactionStatusTypes.PROCESSING]: {
    badgeClassName: 'badge-info',
    text: 'Processing',
  },

  [cryptoWalletTransactionStatusTypes.WAITING_FOR_WITHDRAW]: {
    badgeClassName: 'badge-info',
    text: 'Waiting for withdraw',
  },

  [cryptoWalletTransactionStatusTypes.WAITING_FOR_GAS]: {
    badgeClassName: 'badge-info',
    text: 'Waiting for gas',
  },
};

export const P2P_OFFER_STATUS = [
  {
    id: 0,
    label: 'bo_offer_status_placed',
    value: 'Placed',
    background: '#0408491A',
    color: '#040849',
  },
  {
    id: 1,
    label: 'bo_exchange_offer_status_on_going',
    value: 'On Going',
    background: '#a8a8ad',
    color: '#313133',
  },
  {
    id: 2,
    label: 'bo_status_blocked',
    value: 'Blocked',
    background: '#ffd8b2',
    color: '#040849',
  },
  {
    id: 3,
    label: 'bo_status_done',
    value: 'Done',
    background: '#c8e6c9',
    color: '#4caf50',
  },
  {
    id: 4,
    label: 'bo_transaction_status_timeout',
    value: 'tran_expired',
    background: '#0408491A',
    color: '#040849',
  },
];
