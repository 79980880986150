import { LoginAgentActionsTypes } from "./actionTypes";
import { IGetLoginAgentError, IGetLoginAgentAttempt, IGetLoginAgentSuccess, IGetVerifyOTPAttempt, IGetVerifyOTPSuccess, IGetVerifyOTPError, IGetVerifyPINAttempt, IGetVerifyPINSuccess, IGetVerifyPINError } from "./interfaces";


export const checkAgent = (payload: Object) => ({
    type: LoginAgentActionsTypes.GET_LOGIN_AGENT,
    payload
})

export const getLoginAgentAtFetching = (isFetching: boolean): IGetLoginAgentAttempt => ({
    type: LoginAgentActionsTypes.GET_LOGIN_AGENT_ATTEMPT,
    isFetching
})

export const getCheckAgentSuccess = (data: any): IGetLoginAgentSuccess => ({
    type: LoginAgentActionsTypes.GET_LOGIN_AGENT_SUCCESS,
    login: data
})

export const getCheckAgentError = (errorMsg: any): IGetLoginAgentError => ({
    type: LoginAgentActionsTypes.GET_LOGIN_AGENT_FAIL,
    error: errorMsg
})

//------------------------------------------------

export const verifyOTP = (payload: Object) => ({
    type: LoginAgentActionsTypes.VERIFY_OTP,
    payload
})

export const reSendOTP = (payload: Object) => ({
    type: LoginAgentActionsTypes.RESEND_OTP,
    payload
})

export const getVerifyOTPAtFetching = (isFetching: boolean): IGetVerifyOTPAttempt => ({
    type: LoginAgentActionsTypes.VERIFY_OTP_ATTEMPT,
    isFetching
})


export const getVerifyOTPSuccess = (data: any): IGetVerifyOTPSuccess => ({
    type: LoginAgentActionsTypes.VERIFY_OTP_SUCCESS,
    login: data
})

export const getVerifyOTPError = (errorMsg: any): IGetVerifyOTPError => ({
    type: LoginAgentActionsTypes.VERIFY_OTP_FAIL,
    error: errorMsg
})

//-----------------------------------------------------

//------------------------------------------------

export const verifyPIN = (payload: Object) => ({
    type: LoginAgentActionsTypes.VERIFY_PIN,
    payload
})

export const getVerifyPINAtFetching = (isFetching: boolean): IGetVerifyPINAttempt => ({
    type: LoginAgentActionsTypes.VERIFY_PIN_ATTEMPT,
    isFetching
})


export const getVerifyPINSuccess = (data: any): IGetVerifyPINSuccess => ({
    type: LoginAgentActionsTypes.VERIFY_PIN_SUCCESS,
    login: data
})

export const getVerifyPINError = (errorMsg: any): IGetVerifyPINError => ({
    type: LoginAgentActionsTypes.VERIFY_PIN_FAIL,
    error: errorMsg
})

export const clear2FA = (): any => ({
    type: LoginAgentActionsTypes.CLEAR_2FA
})
