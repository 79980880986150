import { PAGE_SIZE_DEFAULT } from 'src/contants/pageSize';

export interface DataBankAccount {
  abaRouting?: string;
  bankName?: string;
  bsdBranch?: string;
  cardHolderName?: string;
  cardNumber?: string;
  cbu?: string;
  countryId?: number;
  createdDate?: string;
  currencyId?: number;
  flagUrl?: null | string;
  isDeleteted?: boolean;
  swiftCode?: string;
  taxID?: string;
  userId?: string;
}

export interface UserState {
  data: DataBankAccount;
  isFetching: boolean;
  status: number;
  pagination: any;
  message: string;
  filter: any;
}

const user: any = localStorage.getItem('user')

export const initialDataBankAccountState: UserState = {
   data:{
    abaRouting: '',
    bankName: '',
    bsdBranch: '',
    cardHolderName: '',
    cardNumber: '',
    cbu: '',
    countryId: 0,
    createdDate: '',
    currencyId: 0,
    flagUrl: null || '',
    swiftCode: '',
    taxID: '',
    userId: '',
    isDeleteted: false
   },
   status:0,
   message:'',
   isFetching:false,
   pagination:{
    totalCount:0
   },
   filter:{
     pageNumber:1,
     pageSize:PAGE_SIZE_DEFAULT
   }
};
