import { AnyAction, Reducer } from 'redux';
import { IGetListCryptoWalletState, initialGetListCryptoWalletState } from './state';
import { GetListCryptoWalletTypes } from 'src/store/actions/getListCryptoWallet';

export const GetListCryptoWalletReducer: Reducer = (
  state: IGetListCryptoWalletState = initialGetListCryptoWalletState,
  action: AnyAction
) => {
  switch (action.type) {
    case GetListCryptoWalletTypes.GET_LIST_CRYPTO_WALLET: {
      return {
        ...state,
        isFetching: true,
      };
    }
    case GetListCryptoWalletTypes.GET_LIST_CRYPTO_WALLET_SUCCESS: {
      return {
        ...state,
        data: action.data,
        isFetching: false,
        pagination: action.data.data.totalCount,
      };
    }
    case GetListCryptoWalletTypes.GET_LIST_CRYPTO_WALLET_FAILD: {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    }
    case GetListCryptoWalletTypes.GET_LIST_CRYPTO_WALLET_FILTER: {
      return {
        ...state,
        filter: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
