import { watchGetListTokenSupport } from './saga/getListTokenSupport';
import { watchGetUnreadCountNotification } from './saga/getUnreadCountNotification';
import { watchPostCreateExchangeTransaction } from './saga/postCreateExchangeTransaction';
import { watchGetCryptoExchangeOffersListHistories } from './saga/getCryptoExchangeOffersListHistories';
import { watchGetUser } from './saga/getUser';
import { watchGenerateCode2FA } from './saga/generateCode2FA';
import { watchSet2FADisable } from './saga/set2FADisable';
import { watchSet2FAEnabled } from './saga/set2FAEnabled';
import { watchDeleteCryptoExchangeOffersList } from './saga/deleteCryptoExchangeOffersList';
import { watchGetCryptoExchangeOffersList } from './saga/getCryptoExchangeOffersList';
import { watchGetListAddressBooks } from './saga/getListAddressBooks';
import { watchGetCryptoTransactionFee } from './saga/getCryptoTransactionFee';
import { watchGetCryptoTransactionHistory } from './saga/getCryptoTransactionHistory';
import { watchGetTokenDetail } from './saga/getTokenDetail';
import { watchEditWalletCrypto } from './saga/editWalletCrypto';
import { watchAgentGetAllWallets } from './saga/agentGetAllWallets';
import { watchAgentTransferMoney } from './saga/agentTransferMoney';
import { watchAgentSellSearch } from './saga/agentSellSearch';
import { watchAgentBuySearch } from './saga/agentBuySearch';
import { watchGetAllCurrencyNoFilter } from './saga/getAllCurrencyNoFilter';
import { watchGetKYCStatus } from './saga/getKYCStatus';
import { watchGetListCryptoDetail } from './saga/getListCryptoDetail';
import { watchGetListCryptoWallet } from './saga/getListCryptoWallet';
import { watchGetTransactionsFilterStatuses } from './saga/getTransactionsFilterStatuses';
import { watchAgentGetAdminTrxDetail } from './saga/agentGetAdminTrxDetail';
import { watchAgentGetDirectDetail } from './saga/agentGetDirectDetail';
import { watchApplyKycDeclaration } from './saga/ApplyKycDeclaration';
import { watchApplyKYCBankInfo } from './saga/ApplyKYCBankInfo';
import { watchApplyKYCDocs } from './saga/applyKYCDocs';
import { watchApplyKYCAddress } from './saga/applyKYCAddress';
import { watchApplyKYCUserInfo } from './saga/ApplyKYCUserInfo';
import { watchGetAgentDirectTransfers } from './saga/getAgentDirectTransfers';
import { watchGetAgentCryptoSetting } from './saga/getAgentCryptoSetting';
import { watchAgentChangePINCode } from './saga/agentChangePINCode';
import { watchGetAdminTransfers } from './saga/getAdminTransfers';
import { watchUserAvatar } from './saga/userAvatar';
import { watchTransactionOverview } from './saga/transactionOverview';
import { watchGetUserWalletOverview } from './saga/getUserWalletOverview';
import { watchHandleGetWalletDetailOverview } from './saga/handleGetWalletDetailOverview';
import { watchGetOfferLogs } from './saga/getOfferLogs';
import { watchWalletInput } from './saga/walletInput';
import { watchGetWalletDetail } from './saga/getWalletDetail';
import { watchSelectWalletType } from './saga/selectWalletType';
import { watchUpdateWalletStatus } from './saga/updateWalletStatus';
import { watchGetListUserWallet } from './saga/getListUserWallet';
import { watchCreateTopupTransaction } from './saga/createTopupTransaction';
import { watchGetListTopupRequest } from './saga/getListTopupRequest';
import { watchGetWalletProductBlockchain } from './saga/getWalletProductBlockchain';
import { watchTopupWalletListSelect } from './saga/topupWalletListSelect';
import { watchGetBlockchainDashboard } from './saga/getBlockchainDashboard';
import { watchUpdateOfferNote } from './saga/updateOfferNote';
import { watchHandleRetryTransaction } from './saga/handleRetryTransaction';
import { watchHandleWithdrawTransaction } from './saga/handleWithdrawTransaction';
import { watchGetListTransactionCrypto } from './saga/getListTransactionCrypto';
import { watchGetOfferDetailExchange } from './saga/getOfferDetailExchange';
import { watchHandleDeleteOffer } from './saga/handleDeleteOffer';
import { watchHandleBlockOffer } from './saga/handleBlockOffer';
import { watchGetOffers } from './saga/getOffers';
import { watchGetOfferHistory } from './saga/getOfferHistory';
import { watchGetInvoiceDetail } from './saga/getInvoiceDetail';
import { watchAdminGetInvoiceList } from './saga/adminGetInvoiceList';
import { watchCheckPasswordAdmin } from './saga/checkPasswordAdmin';
import { watchSetUserLanguage } from './saga/setUserLanguage';
import { watchGetLanguageForBO } from './saga/getLanguageForBO';
import { watchGetAgentRankByAdmin } from './saga/getAgentRankByAdmin';
import { watchActiveAgentRank } from './saga/activeAgentRank';
import { watchGetAdminTransactionUser } from './saga/getAdminTransactionUser';
import { watchGetDirectTransactionDetail } from './saga/getDirectTransactionDetail';
import { watchGetAllTransactionsByAdmin } from './saga/getAllTransactionsByAdmin';
import { watchHandleNotiRefreshData } from './saga/handleNotiRefreshData';
import { watchGetTransactionsFilterTypes } from './saga/getTransactionsFilterTypes';
import { watchAgentTransactionConfirm } from './saga/agentTransactionConfirm';
import { watchAgentSubmitBank } from './saga/agentSubmitBank';
import { watchGetHistoryDeactivateUser } from './saga/getHistoryDeactivateUser';
import { watchAgentTransferredTransaction } from './saga/agentTransferredTransaction';
import { watchVerifyPINByAgent } from './saga/verifyPINByAgent';
import { watchGetTransactionDetailByAgent } from './saga/getTransactionDetailByAgent';
import { watchAgentCancelTransaction } from './saga/agentCancelTransaction';
import { watchGetTranscationsByAgent } from './saga/getTranscationsByAgent';
import { watchGetFileById } from './saga/getFileById';
import { watchEditUserBankInfo } from './saga/editUserBankInfo';
import { watchGetListWalletsForUser } from './saga/getListWalletsForUser';
import { watchGetP2PExchangeHistoryAgent } from './saga/getP2PExchangeHistoryAgent';
import { watchDeleteOfferByAgent } from './saga/deleteOfferByAgent';
import { watchTransactionAgentOverview } from './saga/transactionAgentOverview';
import { watchCreateScorePeriodConfig } from './saga/createScorePeriodConfig';
import { watchGetAllScorePeriodConfig } from './saga/getAllScorePeriodConfig';
import { watchGetAllScoreKPI } from './saga/getAllScoreKPI';
import { watchActiveScoreKPI } from './saga/activeScoreKPI';
import { watchDeactiveScoreKPI } from './saga/deactiveScoreKPI';
import { watchDeleteScoreKPI } from './saga/deleteScoreKPI';
import { watchUpdateScoreKPI } from './saga/updateScoreKPI';
import { watchCreateScoreKPI } from './saga/createScoreKPI';
import { watchGetAllRankPeriod } from './saga/getAllRankPeriod';
import { watchCreateRankPeriodConfig } from './saga/createRankPeriodConfig';
import { watchGetKYCSystemStatusHistory } from './saga/getKYCSystemStatusHistory';
import { watchGetKYCSystemStatus } from './saga/getKYCSystemStatus';
import { watchGetUserKYCPriorityHistory } from './saga/getUserKYCPriorityHistory';
import { watchSetKYCPriorityAllUser } from './saga/setKYCPriorityAllUser';
import { watchSetUserKYCPriority } from './saga/setUserKYCPriority';
import { watchAdminP2PSetAgentScore } from './saga/adminP2PSetAgentScore';
import { watchGetNavbarForAdmin } from './saga/getNavbarForAdmin';
import { watchGetRoleFunctionByAdminId } from './saga/getRoleFunctionByAdminId';
import { watchCreateOrUpdateAdminFunction } from './saga/createOrUpdateAdminFunction';
import { watchGetListAdminFunction } from './saga/getListAdminFunction';
import { all, fork } from 'redux-saga/effects';
import {
  watchAddBankAcc,
  watchAddBankAccAgent,
  watchChangePassword,
  watchChangeWalletUserBalance,
  watchCheckPhone,
  watchChoosePriority,
  watchCreateAdmin,
  watchCreateLanguageText,
  watchCreateNotification,
  watchCreateOfferBuyOrSell,
  watchCreatePIN,
  watchCryptoWalletDashboard,
  watchDeactivateUser,
  watchDeleteAnnouncements,
  watchDeleteAnnouncementsCategory,
  watchEditNotification,
  watchEditOfferBuyOrSell,
  watchEditUserByAdmin,
  watchEditUserPriority,
  watchEditUserScore,
  watchExportCrypto,
  watchExportCryptoReceiveTransaction,
  watchExportCryptoSendTransaction,
  watchExportDirectTransfer,
  watchExportExchange,
  watchExportOfferSearch,
  watchGetAllAdminBO,
  watchGetDataBankAcc,
  watchGetDataBankAccAgent,
  watchGetDirectTransfers,
  watchGetListAgency,
  watchGetListAgencyStatus,
  watchGetListAnnouncements,
  watchGetListCountry,
  watchGetListCryptoWalletReceiveHistory,
  watchGetListCryptoWalletSendHistory,
  watchGetListCurrency,
  watchGetListDashboard,
  watchGetListKycDetail,
  watchGetListKycHistoryNote,
  watchGetListKycListHistory,
  watchGetListLanguageText,
  watchGetListManualPaymentHistory,
  watchGetListNotification,
  watchGetListOfferHistoryStatus,
  watchGetListOfferSellTransaction,
  watchGetListP2PBuyTransactions,
  watchGetListP2PExchangeOffersHistory,
  watchGetListP2PExchangeTransactions,
  watchGetListP2PSellTransactions,
  watchGetListTransaction,
  watchGetListUser,
  watchGetListUserBalanceHistory,
  watchGetListUserDeactivated,
  watchGetListUserPriority,
  watchGetListUserScore,
  watchGetListWallet,
  watchGetOfferDetail,
  watchGetPriority,
  watchGetUserDetail,
  watchLogin,
  watchReactiveUser,
  watchRemoveLanguageText,
  watchRemoveNotification,
  watchRemoveOfferBuyTransaction,
  watchRemoveOfferSellTransaction,
  watchRemoveUserBankAcc,
  watchRemoveUserBankAccAgent,
  watchResendFeaturedNotification,
  watchResetPIN,
  watchSendNoteP2PExchangeOffers,
  watchUnLockUser,
  watchUpdateAgencyStatus,
  watchUpdateLanguageText,
  watchUpdatePriority,
  watchUpdateUserRole,
  watchWalletRefill,
  watchSupportedCryptoCurrenciesSaga,
  watchGetCryptoWalletTransactions,
  watchGetAdminOrAgentTransfers,
} from './saga';
import { watchCreateAnnouncements } from './saga/Announcement/Announcements/CreateAnnouncements';
import { watchUpdateListAnnouncements } from './saga/Announcement/Announcements/UpdateAnnouncements';
import { watchCreateListAnnouncementsCategory } from './saga/Announcement/Categories/CreateAnnouncementsCategory';
import { watchGetListAnnouncementsCategory } from './saga/Announcement/Categories/GetAnnouncementsCategory';
import { watchUpdateListAnnouncementsCategory } from './saga/Announcement/Categories/UpdateAnnouncementsCategory';
import { watchGetListTransactionByOffer } from './saga/TransactionByOffer/saga';
import { watchGetListCurrencyBO } from './saga/currency';
import { watchCreateAndEditCurrency } from './saga/currency/createAndEditCurrency';
import { watchDeleteCurrency } from './saga/currency/deleteCurrency';
import { watchExportFile } from './saga/exportFile';
import { watchGetListOfferTransaction } from './saga/getOfferTransaction/saga';
import { watchGetListKycList } from './saga/kyc/saga';
import { watchGetListKycListByStage } from './saga/kycListByStage';
import { watchGetListKycRiskManagement } from './saga/kycRiskManagement';
import { watchSendNoteKyc } from './saga/noteKyc';
import { watchGetListNotificationsInFirebase } from './saga/notificationsFromFirebase/getAllNotification/saga';
import { watchGetListP2PExchangeOfferDetail } from './saga/P2PExchangeOfferDetail';
import {
  watchBlockOrUnBlockP2PExchangeOfferSaga,
  watchCancelP2PExchangeOfferSaga,
} from './saga/blockOrUnBlockAndCancelP2PExchangeOffer';
import { watchSetUnPostAndPostAnnouncements } from './saga/Announcement/Announcements/SetUnPostAndPost';
import { watchSetMainPostAnnouncements } from './saga/Announcement/Announcements/SetMainPost';
import { watchCreateAndEditAdminBO } from './saga/admin-managements/createAndeditAdminBO';
import { watchDeleteAdminBO } from './saga/admin-managements/removeAdminBO';
import { watchGetAllSuperWalletAdminBO } from './saga/WalletAdminBO/getAllWalletSuperAdminBO';
import { watchCreateWalletAdminBO } from './saga/WalletAdminBO/createWalletAdminBO';
import { watchDeleteWalletAdminBO } from './saga/WalletAdminBO/deleteWalletAdminBO';
import { watchGetListP2PExchangeOffers } from './saga/P2PExchangeOffers';
import { watchTransactionHistoryLog } from './saga/transactionHistoryStatusLog/saga';
import { watchTransactionManualPayment } from './saga/transactionManualPayment';
import { watchTransactionManualPaymentHistory } from './saga/transactionManualPaymentHistory';
import { watchUpdateKycSetting } from './saga/updateKycSetting';
import { watchUpdateKycStage } from './saga/updateKycStage';
import { watchUpdateKycStepStatus } from './saga/updateKycStepStatus';
import { watchGetListP2PExchangeTransactionsHistory } from './saga/P2PExchangeTransactionHistory';
import { watchGetListCryptoActivatedWalletList } from './saga/CryptoActivatedWalletList';
import { watchGetListCryptoWalletOverview } from './saga/CryptoWalletGetOverview';
import { watchDeleteAgentRank, watchGetAllAgentRank } from './saga/Agent';
import { watchsetAndEditAgentRankBO } from './saga/Agent/setAgentRank';
import { watchGetListCryptoWalletOverviewTransactionCrypto } from './saga/CryptoWalletGetOverviewTransactionCrypto';
import { watchGetListP2PExchangeTransactionsAgent } from './saga/P2PExchangeTransactionsAgent';
import { watchGetListWalletsUser } from './saga/getWalletsUser';
import { watchCreateExchangeOffer } from './saga/createExchangeOffer';
import { watchUpdateExchangeOffer } from './saga/updateExchangeOffer';
import { watchRemoveExchangeOffer } from './saga/removeExchangeOffer';
import { watchGetListVenlyWalletsUser } from './saga/getVenlyWalletsUser';
import { watchGetListP2PExchangeOffersAgent } from './saga/P2PExchangeOffersAgent';
import { watchLoginAgent } from './saga/loginAgent';
import { watchGetListOfferAll } from './saga/getOfferAll';
import { watchRemoveOfferAll } from './saga/removeOfferAll';
import { watchGetListTest } from './saga/testSaga';
import { watchActiveCurrency } from './saga/currency/activeCurrency';
import { watchGetP2PExRankAndScore } from './saga/getP2PExRankAndScore';
import { watchGetAllRankConfig } from './saga/getAllRankConfig';
import { watchGetScoreHistory } from './saga/getScoreHistory';
import { watchGetListCryptoWalletNewOverview } from './saga/CryptoWalletGetNewOverview';
import { watchGetListAdminRoles } from './saga/getListAdminRoles';
import { watchGetAllKYC } from './saga/getAllKYC';
import { watchCreateCryptoExchangeOffersList } from './saga/createCryptoExchangeOffersList';
import { watchUpdateCryptoExchangeOffersList } from './saga/updateCryptoExchangeOffersList';
import { watchPostCryptoCreateExchangeTransaction } from './saga/postCryptoCreateExchangeTransaction';
import { watchGetAllWalletAdminBO } from './saga/WalletAdminBO/getAllWalletAdminBO';

function* rootSaga(): any {
  yield all([fork(watchCheckPhone)]);
  yield all([fork(watchLogin)]);
  yield all([fork(watchGetDataBankAcc)]);
  yield all([fork(watchChangePassword)]);
  yield all([fork(watchGetListUserScore)]);
  yield all([fork(watchEditUserScore)]);
  yield all([fork(watchGetListUserPriority)]);
  yield all([fork(watchGetListTransaction)]);
  yield all([fork(watchEditUserPriority)]);
  yield all([fork(watchUpdateUserRole)]);
  yield all([fork(watchAddBankAcc)]);
  yield all([fork(watchRemoveUserBankAcc)]);
  yield all([fork(watchChoosePriority)]);
  yield all([fork(watchGetListUser)]);
  yield all([fork(watchGetUserDetail)]);
  yield all([fork(watchGetListWallet)]);
  yield all([fork(watchChangeWalletUserBalance)]);
  yield all([fork(watchGetListAgency)]);
  yield all([fork(watchGetListAgencyStatus)]);
  yield all([fork(watchUpdateAgencyStatus)]);
  yield all([fork(watchCreatePIN)]);
  yield all([fork(watchResetPIN)]);
  yield all([fork(watchReactiveUser)]);
  yield all([fork(watchUnLockUser)]);
  yield all([fork(watchDeactivateUser)]);
  yield all([fork(watchCreateAdmin)]);
  yield all([fork(watchGetListLanguageText)]);
  yield all([fork(watchUpdateLanguageText)]);
  yield all([fork(watchCreateLanguageText)]);
  yield all([fork(watchGetListAnnouncements)]);
  yield all([fork(watchUpdateListAnnouncements)]);
  yield all([fork(watchCreateAnnouncements)]);
  yield all([fork(watchGetListAnnouncementsCategory)]);
  yield all([fork(watchUpdateListAnnouncementsCategory)]);
  yield all([fork(watchCreateListAnnouncementsCategory)]);
  yield all([fork(watchDeleteAnnouncementsCategory)]);
  yield all([fork(watchGetListNotification)]);
  yield all([fork(watchEditNotification)]);
  yield all([fork(watchCreateNotification)]);
  yield all([fork(watchRemoveNotification)]);
  yield all([fork(watchWalletRefill)]);
  yield all([fork(watchGetListP2PBuyTransactions)]);
  yield all([fork(watchDeleteAnnouncements)]);
  yield all([fork(watchGetListManualPaymentHistory)]);
  yield all([fork(watchExportFile)]);
  yield all([fork(watchTransactionManualPayment)]);
  yield all([fork(watchTransactionManualPaymentHistory)]);
  yield all([fork(watchGetListOfferHistoryStatus)]);
  yield all([fork(watchGetListCountry)]);
  yield all([fork(watchGetListCurrency)]);
  yield all([fork(watchRemoveLanguageText)]);
  yield all([fork(watchGetListOfferTransaction)]);
  yield all([fork(watchGetListP2PSellTransactions)]);
  yield all([fork(watchGetListOfferSellTransaction)]);
  yield all([fork(watchRemoveOfferBuyTransaction)]);
  yield all([fork(watchRemoveOfferSellTransaction)]);
  yield all([fork(watchGetListNotificationsInFirebase)]);
  yield all([fork(watchTransactionHistoryLog)]);
  yield all([fork(watchGetAdminOrAgentTransfers)]);
  yield all([fork(watchGetDirectTransfers)]);
  yield all([fork(watchGetListKycList)]);
  yield all([fork(watchGetListKycDetail)]);
  yield all([fork(watchGetListKycListByStage)]);
  yield all([fork(watchGetListKycRiskManagement)]);
  yield all([fork(watchUpdateKycSetting)]);
  yield all([fork(watchUpdateKycStage)]);
  yield all([fork(watchUpdateKycStepStatus)]);
  yield all([fork(watchGetListKycListHistory)]);
  yield all([fork(watchSendNoteKyc)]);
  yield all([fork(watchGetListCurrencyBO)]);
  yield all([fork(watchCreateAndEditCurrency)]);
  yield all([fork(watchDeleteCurrency)]);
  yield all([fork(watchGetListP2PExchangeOffers)]);
  yield all([fork(watchGetListP2PExchangeTransactions)]);
  yield all([fork(watchGetListP2PExchangeOfferDetail)]);
  yield all([fork(watchSendNoteP2PExchangeOffers)]);
  yield all([fork(watchBlockOrUnBlockP2PExchangeOfferSaga)]);
  yield all([fork(watchCancelP2PExchangeOfferSaga)]);
  yield all([fork(watchGetListUserBalanceHistory)]);
  yield all([fork(watchSetUnPostAndPostAnnouncements)]);
  yield all([fork(watchSetMainPostAnnouncements)]);
  yield all([fork(watchGetAllAdminBO)]);
  yield all([fork(watchCreateAndEditAdminBO)]);
  yield all([fork(watchDeleteAdminBO)]);
  yield all([fork(watchGetAllSuperWalletAdminBO)]);
  yield all([fork(watchGetAllWalletAdminBO)]);
  yield all([fork(watchCreateWalletAdminBO)]);
  yield all([fork(watchDeleteWalletAdminBO)]);
  yield all([fork(watchGetListP2PExchangeOffersHistory)]);
  yield all([fork(watchResendFeaturedNotification)]);
  yield all([fork(watchGetListP2PExchangeTransactionsHistory)]);
  yield all([fork(watchGetPriority)]);
  yield all([fork(watchUpdatePriority)]);
  yield all([fork(watchGetAllAgentRank)]);
  yield all([fork(watchsetAndEditAgentRankBO)]);
  yield all([fork(watchDeleteAgentRank)]);
  yield all([fork(watchGetListCryptoWalletSendHistory)]);
  yield all([fork(watchGetListCryptoWalletReceiveHistory)]);
  yield all([fork(watchCreateOfferBuyOrSell)]);
  yield all([fork(watchEditOfferBuyOrSell)]);
  yield all([fork(watchGetListCryptoActivatedWalletList)]);
  yield all([fork(watchGetListCryptoWalletOverview)]);
  yield all([fork(watchCryptoWalletDashboard)]);
  yield all([fork(watchGetListCryptoWalletNewOverview)]);
  yield all([fork(watchGetListCryptoWalletOverviewTransactionCrypto)]);
  yield all([fork(watchGetDataBankAccAgent)]);
  yield all([fork(watchAddBankAccAgent)]);
  yield all([fork(watchRemoveUserBankAccAgent)]);
  yield all([fork(watchGetListP2PExchangeTransactionsAgent)]);
  yield all([fork(watchGetListWalletsUser)]);
  yield all([fork(watchGetListVenlyWalletsUser)]);
  yield all([fork(watchCreateExchangeOffer)]);
  yield all([fork(watchUpdateExchangeOffer)]);
  yield all([fork(watchRemoveExchangeOffer)]);
  yield all([fork(watchGetListP2PExchangeOffersAgent)]);
  yield all([fork(watchGetOfferDetail)]);
  yield all([fork(watchGetListTransactionByOffer)]);
  yield all([fork(watchGetListUserDeactivated)]);
  yield all([fork(watchExportCrypto)]);
  yield all([fork(watchExportCryptoSendTransaction)]);
  yield all([fork(watchExportCryptoReceiveTransaction)]);
  yield all([fork(watchExportDirectTransfer)]);
  yield all([fork(watchExportOfferSearch)]);
  yield all([fork(watchEditUserByAdmin)]);
  yield all([fork(watchExportExchange)]);
  yield all([fork(watchLoginAgent)]);
  yield all([fork(watchGetListDashboard)]);
  yield all([fork(watchGetListKycHistoryNote)]);
  yield all([fork(watchGetListOfferAll)]);
  yield all([fork(watchRemoveOfferAll)]);
  yield all([fork(watchGetListTest)]);
  yield all([fork(watchActiveCurrency)]);
  yield all([fork(watchGetP2PExRankAndScore)]);
  yield all([fork(watchGetAllRankConfig)]);
  yield all([fork(watchGetScoreHistory)]);
  yield all([fork(watchAdminP2PSetAgentScore)]);
  yield all([fork(watchSetUserKYCPriority)]);
  yield all([fork(watchSetKYCPriorityAllUser)]);
  yield all([fork(watchGetListAdminFunction)]);
  yield all([fork(watchGetListAdminRoles)]);
  yield all([fork(watchCreateOrUpdateAdminFunction)]);
  yield all([fork(watchGetRoleFunctionByAdminId)]);
  yield all([fork(watchGetNavbarForAdmin)]);
  yield all([fork(watchGetUserKYCPriorityHistory)]);
  yield all([fork(watchGetKYCSystemStatus)]);
  yield all([fork(watchGetKYCSystemStatusHistory)]);
  yield all([fork(watchCreateRankPeriodConfig)]);
  yield all([fork(watchGetAllRankPeriod)]);
  yield all([fork(watchCreateScoreKPI)]);
  yield all([fork(watchUpdateScoreKPI)]);
  yield all([fork(watchDeleteScoreKPI)]);
  yield all([fork(watchDeactiveScoreKPI)]);
  yield all([fork(watchActiveScoreKPI)]);
  yield all([fork(watchGetAllScoreKPI)]);
  yield all([fork(watchGetAllScorePeriodConfig)]);
  yield all([fork(watchCreateScorePeriodConfig)]);
  yield all([fork(watchTransactionAgentOverview)]);
  yield all([fork(watchDeleteOfferByAgent)]);
  yield all([fork(watchGetP2PExchangeHistoryAgent)]);
  yield all([fork(watchGetTranscationsByAgent)]);
  yield all([fork(watchAgentCancelTransaction)]);
  yield all([fork(watchGetTransactionDetailByAgent)]);
  yield all([fork(watchVerifyPINByAgent)]);
  yield all([fork(watchAgentTransferredTransaction)]);
  yield all([fork(watchGetListWalletsForUser)]);
  yield all([fork(watchEditUserBankInfo)]);
  yield all([fork(watchGetFileById)]);
  yield all([fork(watchGetHistoryDeactivateUser)]);
  yield all([fork(watchAgentSubmitBank)]);
  yield all([fork(watchAgentTransactionConfirm)]);
  yield all([fork(watchGetTransactionsFilterTypes)]);
  yield all([fork(watchHandleNotiRefreshData)]);
  yield all([fork(watchGetAllTransactionsByAdmin)]);
  yield all([fork(watchGetDirectTransactionDetail)]);
  yield all([fork(watchGetAdminTransactionUser)]);
  yield all([fork(watchActiveAgentRank)]);
  yield all([fork(watchGetAgentRankByAdmin)]);
  yield all([fork(watchGetLanguageForBO)]);
  yield all([fork(watchSetUserLanguage)]);
  yield all([fork(watchCheckPasswordAdmin)]);
  yield all([fork(watchAdminGetInvoiceList)]);
  yield all([fork(watchGetInvoiceDetail)]);
  yield all([fork(watchSupportedCryptoCurrenciesSaga)]);
  yield all([fork(watchGetCryptoWalletTransactions)]);
  yield all([fork(watchGetOfferHistory)]);
  yield all([fork(watchGetOffers)]);
  yield all([fork(watchGetBlockchainDashboard)]);
  yield all([fork(watchTopupWalletListSelect)]);
  yield all([fork(watchGetWalletProductBlockchain)]);
  yield all([fork(watchGetListTopupRequest)]);
  yield all([fork(watchCreateTopupTransaction)]);
  yield all([fork(watchSelectWalletType)]);
  yield all([fork(watchWalletInput)]);
  yield all([fork(watchGetListUserWallet)]);
  yield all([fork(watchUpdateWalletStatus)]);
  yield all([fork(watchHandleBlockOffer)]);
  yield all([fork(watchGetOffers)]);
  yield all([fork(watchHandleDeleteOffer)]);
  yield all([fork(watchGetOfferDetailExchange)]);
  yield all([fork(watchGetListTransactionCrypto)]);
  yield all([fork(watchHandleWithdrawTransaction)]);
  yield all([fork(watchHandleRetryTransaction)]);
  yield all([fork(watchUpdateOfferNote)]);
  yield all([fork(watchGetWalletDetail)]);
  yield all([fork(watchHandleGetWalletDetailOverview)]);
  yield all([fork(watchGetUserWalletOverview)]);
  yield all([fork(watchTransactionOverview)]);
  yield all([fork(watchGetOfferLogs)]);
  yield all([fork(watchUserAvatar)]);
  yield all([fork(watchGetAdminTransfers)]);
  yield all([fork(watchAgentChangePINCode)]);
  yield all([fork(watchGetAgentCryptoSetting)]);
  yield all([fork(watchGetAgentDirectTransfers)]);
  yield all([fork(watchGetListCryptoWallet)]);
  yield all([fork(watchGetListCryptoDetail)]);
  yield all([fork(watchGetKYCStatus)]);
  yield all([fork(watchGetAllCurrencyNoFilter)]);
  yield all([fork(watchGetAllKYC)]);
  yield all([fork(watchApplyKYCUserInfo)]);
  yield all([fork(watchApplyKYCAddress)]);
  yield all([fork(watchApplyKYCDocs)]);
  yield all([fork(watchApplyKYCBankInfo)]);
  yield all([fork(watchApplyKycDeclaration)]);
  yield all([fork(watchAgentGetDirectDetail)]);
  yield all([fork(watchAgentGetAdminTrxDetail)]);
  yield all([fork(watchGetTransactionsFilterStatuses)]);
  yield all([fork(watchAgentBuySearch)]);
  yield all([fork(watchAgentSellSearch)]);
  yield all([fork(watchAgentTransferMoney)]);
  yield all([fork(watchAgentGetAllWallets)]);
  yield all([fork(watchGetCryptoExchangeOffersList)]);
  yield all([fork(watchCreateCryptoExchangeOffersList)]);
  yield all([fork(watchUpdateCryptoExchangeOffersList)]);
  yield all([fork(watchEditWalletCrypto)]);
  yield all([fork(watchGetTokenDetail)]);
  yield all([fork(watchGetCryptoTransactionHistory)]);
  yield all([fork(watchDeleteCryptoExchangeOffersList)]);
  yield all([fork(watchGetCryptoTransactionFee)]);
  yield all([fork(watchGetListAddressBooks)]);
  yield all([fork(watchSet2FAEnabled)]);
  yield all([fork(watchSet2FADisable)]);
  yield all([fork(watchGenerateCode2FA)]);
  yield all([fork(watchGetUser)]);
  yield all([fork(watchGetCryptoExchangeOffersListHistories)]);
  yield all([fork(watchPostCreateExchangeTransaction)]);
  yield all([fork(watchPostCryptoCreateExchangeTransaction)]);
  yield all([fork(watchGetUnreadCountNotification)]);
  yield all([fork(watchGetListTokenSupport)]);
}

export default rootSaga;
