export const KYC_STATUS_SUM = [
  {
    id: 0,
    value: 0,
    label: 'bo_status_off',
  },
  {
    id: 1,
    value: 1,
    label: 'bo_status_on',
  },
];
export const KYC_STEP = [
  {
    id: 1,
    value: 'bo_general_kyc',
    label: 'bo_general_kyc',
  },
  {
    id: 2,
    value: 'bo_bank_information',
    label: 'bo_bank_information',
  },
  {
    id: 3,
    value: 'bo_declaration_information',
    label: 'bo_declaration_information',
  },
  {
    id: 4,
    value: 'bo_identity_document',
    label: 'bo_identity_document',
  },
  {
    id: 5,
    value: 'bo_user_address_information',
    label: 'bo_user_address_information',
  },
  {
    id: 6,
    value: 'bo_user_information',
    label: 'bo_user_information_label',
  },
];

export const KYC_STAGE = [
  // {
  //   id: 0,
  //   value: 0,
  //   name: 'waitingForKYC',
  //   label: 'WaitingForKYC ',
  //   background: '#000000',
  //   color: '#fff'
  // },
  {
    id: 1,
    value: 1,
    name: 'bo_applied',
    label: 'bo_kyc_status_applied',
    background: '#38CAB3',
    color: '#fff',
    selectColor: 'bg-gray-600',
  },
  {
    id: 2,
    value: 2,
    name: 'bo_status_pending',
    label: 'bo_status_pending',
    background: '#F29F05',
    color: '#fff',
    selectColor: 'bg-warning',
  },
  {
    id: 3,
    value: 3,
    name: 'bo_approved',
    label: 'bo_approved_status',
    background: '#038757',
    color: '#fff',
    selectColor: 'bg-success',
  },
  {
    id: 4,
    value: 4,
    name: 'bo_kyc_status_rejected',
    label: 'bo_kyc_status_rejected',
    background: '#FF0505',
    color: '#fff',
    selectColor: 'bg-secondary',
  },
  {
    id: 5,
    value: 5,
    name: 'bo_blacklisted',
    label: 'bo_kyc_status_blacklisted',
    background: '#38403a',
    color: '#fff',
    selectColor: 'bg-black',
  },
  {
    id: 6,
    value: 6,
    name: 'bo_limited_kyc',
    label: 'bo_limited_kyc',
    background: '#038757',
    color: '#fff',
    selectColor: 'bg-success',
  },
];

export enum KYC_STAGE_STATUS {
  LIMITED_KYC = 6,
}

export const KYC_HISTORY = [
  {
    id: 3,
    value: 3,
    name: 'bo_applied',
    label: 'bo_kyc_status_applied',
    background: '#000000',
    color: '#fff',
  },
  {
    id: 1,
    value: 1,
    name: 'bo_status_pending',
    label: 'bo_status_pending',
    background: '#596E73',
    color: '#fff',
  },
  {
    id: 2,
    value: 2,
    name: 'bo_approved',
    label: 'bo_approved_status',
    background: '#038757',
    color: '#fff',
  },
  {
    id: 0,
    value: 0,
    name: 'bo_kyc_status_rejected',
    label: 'bo_kyc_status_rejected',
    background: '#FF0505',
    color: '#fff',
  },
];

export const KYC_DOCUMENT_TYPE = [
  {
    id: 0,
    value: 0,
    name: 'KYCBankInfo',
  },
  {
    id: 1,
    value: 1,
    name: 'KYCDeclarations',
  },
  {
    id: 2,
    value: 2,
    name: 'KYCDocs',
  },
  {
    id: 3,
    value: 3,
    name: 'KYCUserAddress',
  },
  {
    id: 4,
    value: 4,
    name: 'KYCUserInfo',
  },
];

export const KYC_STAGE_FILTER = [
  {
    id: 2,
    value: 2,
    name: 'bo_status_pending',
    label: 'bo_status_pending',
    background: '#596E73',
    color: '#fff',
    sqlValue: 2,
  },
  {
    id: 3,
    value: 3,
    name: 'bo_approved',
    label: 'bo_approved_status',
    background: '#038757',
    color: '#fff',
    sqlValue: 3,
  },
  {
    id: 4,
    value: 4,
    name: 'bo_kyc_status_rejected',
    label: 'bo_kyc_status_rejected',
    background: '#FF0505',
    color: '#fff',
    sqlValue: 4,
  },
];

export const STATUS_STEP = [
  {
    label: 'bo_kyc_status_rejected',
    value: 0,
    background: '#FF0505',
    color: '#fff',
    selectColor: 'bg-secondary',
  },
  {
    label: 'bo_status_pending',
    value: 1,
    background: '#FAA500',
    color: '#fff',
    selectColor: 'bg-warning',
  },
  {
    label: 'bo_approved_status',
    value: 2,
    background: '#038757',
    color: '#fff',
    selectColor: 'bg-success',
  },
  {
    label: 'bo_kyc_status_for_review',
    value: 3,
    background: '#000000',
    color: '#fff',
    selectColor: 'bg-info',
  },
];
