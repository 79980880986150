import * as Sentry from '@sentry/react';
import { getToken, onMessage } from 'firebase/messaging';
import Cookies from 'js-cookie';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import * as CONSTANT from 'src/contants';
import { handleLogout } from './apis/service/logout';
import { UserRole } from './models/roles';
import RouterComponent from './routers';
import AuthenticationProvider from './routers/authProvider';
import { IApplicationState } from './store/IApplicationState';
import {
  fetchListCountry,
  getCurrency,
  logout,
  resetCheckPhone,
  saveNewNotification,
} from './store/actions';
import { getAgentCryptoSetting } from './store/actions/getAgentCryptoSetting';
import { getTransactionsFilterStatuses } from './store/actions/getTransactionsFilterStatuses';
import { getTransactionsFilterTypes } from './store/actions/getTransactionsFilterTypes';
import { handleNotiRefreshData } from './store/actions/handleNotiRefreshData';
import { messaging } from './utils/firebase/config';
import roleTableService from './utils/roleTableService';
import {
  clearStorageItems,
  getStorageItem,
  setStorageItem,
} from './utils/storage';
import { routerConst } from './routers/routerConstants';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const isAgent = roleTableService([UserRole.Agent, UserRole.SuperAgent]);
  useTranslation();

  const event = useSelector(
    (state: IApplicationState) =>
      state.listNotificationFirebase.newNotifications
  );
  const options: any = {
    autoClose: 3000,
    pauseOnFocusLoss: true,
    hideProgressBar: true,
    position: 'bottom-right',
    pauseOnHover: true,
    // and so on ...
  };

  const renderHtml = (text: string) => {
    return <div dangerouslySetInnerHTML={{ __html: text }}></div>;
  };

  React.useEffect(() => {
    getFireBaseToken();
    dispatch(getCurrency());
    dispatch(fetchListCountry());
    dispatch(getTransactionsFilterTypes());
    dispatch(getTransactionsFilterStatuses({}));
    if (isAgent) {
      // dispatch(getUser());
      dispatch(getAgentCryptoSetting({}));
    }
    const channel = new BroadcastChannel('notifications');
    channel.addEventListener('message', (event) => {
      console.log(event.data, 'channel.addEventListener');
      handleMessageFireBase(event.data);
    });
  }, []);

  const requestNotificationPermission = async () => {
    await window.Notification.requestPermission();
  };

  React.useEffect(() => {
    onMessage(messaging, (payload) => {
      console.log(payload, 'payload');
      handleMessageFireBase(payload, true);
    });
  }, [event]);

  const handleMessageFireBase = (payload: any, isForeground?: boolean) => {
    const { data } = payload;
    try {
      if (data?.event === 'userStatusDeactive') {
        deactiveUser();
      }
      const object = data?.dto ? JSON.parse(data?.dto) : '';
      const title = object?.title;
      const content = object?.html;
      const Msg = () => {
        return (
          <>
            <div>{title}</div>
            <div>{renderHtml(content)}</div>
          </>
        );
      };
      if (title !== undefined && content !== undefined) {
        if (isForeground) {
          toast.success(<Msg />, options);
        }
        dispatch(saveNewNotification(object));
      }
      if (data) {
        dispatch(handleNotiRefreshData(object));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const deactiveUser = async () => {
    if (isAgent) {
      const firebaseToken: any = getStorageItem('firebase-token');
      var payload = {
        fireBaseToken: firebaseToken,
        isMobile: false,
      };
      await handleLogout(payload);
    }
    dispatch(logout());
    // reset checkPhone in reducer not use checkPhoneFaild action
    dispatch(resetCheckPhone());
    clearStorageItems();
    Cookies.remove('token');
    setStorageItem(CONSTANT.DEACTIVE_USER, 'true');
    const pathName = window.location.pathname;

    if (pathName !== routerConst.Login) {
      window.location.href = routerConst.Login;
    }
  };

  const getFireBaseToken = () => {
    const firebaseToken: any = getStorageItem('firebase-token');
    console.log(firebaseToken, 'firebaseToken');
    console.log(
      Notification.permission === 'granted' && !firebaseToken,
      "Notification.permission === 'granted' && !firebaseToken"
    );

    if (Notification.permission === 'granted' && !firebaseToken) {
      getToken(messaging, {
        vapidKey:
          'BOdbArERgAYHwYd7oFLxWDukwneiBS9aNXjPJL-FNwxOTRjVCki-FLnDtmeX-Gwhg9nNoHjdzBn49xwDyeBqPaY',
      })
        .then((currentToken) => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            // ...
            setStorageItem('firebase-token', currentToken);
          } else {
            // Show permission request UI
            console.log(
              'No registration token available. Request permission to generate one.'
            );
            // ...
          }
        })
        .catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
          // ...
        });
    }
  };

  React.useEffect(() => {
    requestNotificationPermission();
  }, []);

  // useEffect(() => {
  //   if (i18n.isInitialized) {
  //     setIsloading(false);
  //   }
  // }, [i18n]);

  // const renderPreloader = () => {
  //   return (
  //     <div
  //       style={{
  //         width: '100%',
  //         height: '100%',
  //         display: 'flex',
  //         justifyContent: 'center'
  //       }}
  //     >
  //       <div style={{ position: 'absolute', top: '45%' }}>
  //         <img src="/assets/hola_logo.svg" height={40} alt="logo" />
  //       </div>
  //       <div style={{ position: 'absolute', bottom: '0%' }}>
  //         <img src="/assets/icons/logo.svg" height={40} alt="logo" />
  //       </div>
  //     </div>
  //   );
  // };

  // return
  // isloading ? (
  //   renderPreloader()
  // ) :

  return (
    <AuthenticationProvider>
      <RouterComponent />
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={1}
      />
    </AuthenticationProvider>
  );
};

export default Sentry.withProfiler(App);
