import { put, takeLatest } from "redux-saga/effects";
import { handleAgentGetSupportedCrypto } from "src/apis/service/Agent";
import { getSupportedCryptoCurrenciesAPI } from "src/apis/service/Currencies";
import { updateTokenFirebase } from "src/apis/service/Notifications";
import {
  handleCheckAgent,
  sendOTP,
  verifyOTPService,
  verifyPINService,
} from "src/apis/service/user";
import * as CONSTANT from "src/contants";
import { setToken } from "src/helpers/saveCookies";
import { toastError, toastSuccess } from "src/helpers/toastHelper";
import { getAgentCryptoSetting } from "src/store/actions/getAgentCryptoSetting";
import {
  getCheckAgentError,
  getCheckAgentSuccess,
  getLoginAgentAtFetching,
  getVerifyOTPAtFetching,
  getVerifyOTPError,
  getVerifyOTPSuccess,
  getVerifyPINAtFetching,
  getVerifyPINError,
  getVerifyPINSuccess,
  LoginAgentActionsTypes,
} from "src/store/actions/loginAgent";
import { getStorageItem, setStorageItem } from "src/utils/storage";

function* getCheckUser(action: any): Generator<any> {
  yield getLoginAgentAtFetching(true);
  const { payload } = action;
  try {
    const dataLogin: any = yield handleCheckAgent(payload);
    if (dataLogin?.status === 200 && dataLogin?.data.phoneNumberExist) {
      if (dataLogin.data.roleId !== 2 && dataLogin.data.roleId !== 7) {
        yield put(getCheckAgentError(dataLogin));
        toastError("bo_you_are_not_agent");
      } else {
        const sendOTPData: any = yield sendOTP(payload);
        yield put(
          getCheckAgentSuccess({
            ...dataLogin,
            expiredDate: sendOTPData?.data?.expiredDate,
            success: true,
          })
        );
      }
    } else {
      yield put(getCheckAgentError(dataLogin));
      toastError("bo_phone_number_not_exits_error");
    }
    if (dataLogin?.status === 400) {
      yield put(getCheckAgentError(dataLogin));
      toastError("bo_phone_number_not_exits_error");
    }
  } catch (error) { }
}

function* verifyOTP(action: any): Generator<any> {
  yield getVerifyOTPAtFetching(true);
  const { payload } = action;
  try {
    const data: any = yield verifyOTPService(payload);
    if (data?.status === 200) {
      toastSuccess("bo_verify_otp_success");
      yield put(getVerifyOTPSuccess(data));
    } else {
      yield put(getVerifyOTPError(data));
      toastError("bo_invalid_otp_please_try_again");
    }
  } catch (error) { }
}

function* verifyPIN(action: any): Generator<any> {
  yield getVerifyPINAtFetching(true);
  const { payload } = action;
  try {
    const dataLogin: any = yield verifyPINService(payload);
    if (dataLogin?.status === 200) {
      const data = {
        ...dataLogin,
        success: true,
        twoFAKey: dataLogin.data.twoFATokenValue
      };
      
      if (data.twoFAKey) {
        yield put(getVerifyPINSuccess(data));
      } else {
        const token = dataLogin.data && dataLogin.data.token;
        const refreshToken = dataLogin.data && dataLogin.data.refreshToken;

        setStorageItem("user", JSON.stringify(dataLogin.data));
        setToken(token);
        setStorageItem(CONSTANT.ACCESS_TOKEN, token);
        setStorageItem(CONSTANT.REFRESH_TOKEN, refreshToken);
        toastSuccess("bo_login_success");
        const firebaseToken: any = getStorageItem("firebase-token");
        // yield handleAgentGetSupportedCrypto();
        yield updateTokenFirebase(firebaseToken);
        yield put(getVerifyPINSuccess(data));
      }
    } else {
      yield put(getVerifyPINError(dataLogin));
      if (dataLogin?.message === "pin.incorrect") {
        toastError("bo_incorrect_pin_please_try_again");
      } else if (dataLogin?.message === "user.locked") {
        toastError("bo_your_account_has_been_locked_please_contact_support");
      } else {
        toastError("bo_error_label");
      }
    }
  } catch (error) { }
}

export function* watchLoginAgent(): any {
  yield takeLatest(LoginAgentActionsTypes.GET_LOGIN_AGENT, getCheckUser);
  yield takeLatest(LoginAgentActionsTypes.VERIFY_OTP, verifyOTP);
  yield takeLatest(LoginAgentActionsTypes.VERIFY_PIN, verifyPIN);
}
