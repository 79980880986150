import { lazy } from 'react';
import { routerConst } from '../routerConstants';

const Login = lazy(() => import('src/pages/Authentication/Login/Login'));
const Auth = lazy(() => import('src/pages/Authentication/auth'));

export const publicRoutes = [
  {
    path: '/',
    element: <Auth />,
    children: [
      { path: '/', element: <Login /> },
      { path: routerConst.Login, element: <Login /> },
    ],
  },
];
